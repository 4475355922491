.LogoText
{
  color: white;
    font-size: 22px;
}
.LogoText:hover{
  color: white;
  cursor: pointer;
}
.seperatorTitle{
    font-size: 12px;
    font-weight: 700;
    padding-left: 18px;
    margin-bottom: -5px !important
}
.expansion-panel.MuiPaper-root{
  background-color: transparent;
}
.expansion-panel, .MuiPaper-elevation1{
  box-shadow: 0px 0px 0px !important;
}
.MuiExpansionPanelSummary-root{
  padding: 0px 10px 0 17px !important;
  color: white !important;
}
.MuiExpansionPanelDetails-root{
  padding: 0px 20px 0px !important;
}
.moreIcon{
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px !important;
  opacity: 0.8;
}
