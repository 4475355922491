.action-btn{
  min-width: 70px;
  height: 25px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
.action-btn-outline-info {
  /* min-width: 70px;
  height: 25px; */
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  color: #76ab30 !important;
  background-color: transparent !important;
  background-image: none !important;
  border: 1px solid #76ab30 !important
}

.action-btn-outline-info:hover {
  /* min-width: 70px;
  height: 25px; */
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  color: white !important;
  background: #76ab30 !important;
}

.edit-btn{
  min-width: 70px;
  height: 25px;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}

.edit-btn-outline-info {
  /* min-width: 70px;
  height: 25px; */
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  color: #76ab30 !important;
  background-color: transparent !important;
  background-image: none !important;
  border: 1px solid #76ab30 !important
}

.edit-btn-outline-info:hover {
  /* min-width: 70px;
  height: 25px; */
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  color: white !important;
  background: #76ab30 !important;
}

.modal-container{
}
.modal-content-title {
    width: 30%;
    font-size: 16px;
    font-weight: 600;
}
.modal-content-data{
  width: 70%;
  font-size: 16px;
}
.single-row{
  display: flex;
  align-items: center;
  background: #f4f4f4;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 6px
}
.main-container{
  background-color: white;
    /* height: calc(100vh - 105px); */
    padding: 20px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 3px 8px -3px rgba(0,0,0,0.47);
-moz-box-shadow: 0px 3px 8px -3px rgba(0,0,0,0.47);
box-shadow: 0px 3px 8px -3px rgba(0,0,0,0.47);
}
