.validatorForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    max-width: 320px;
    width: 100%;
    /* background-color: yellow; */
  }
  .MyTextField{
    width: 100%;
    min-width: 250px;
    max-width: 350px;
    min-height: 50px;
    /* background-color: red; */
  }
  .MyTextFieldNumber{
    width: 100%;
    min-width: 400px;
    max-width: 500px;
    min-height: 50px;
    /* background-color: red; */
  }
  .numberFormatCap{
    border: none;
    height: 30px;
    width: 320px;
  }
  .numberFormatCap:focus{
    border: none;
  }
  .numberFormatCap:active{
    border: none;
  }

  .MyTextFieldNumber .MuiInputBase-input.Mui-disabled{
    width: 30px;
    text-align: right;
  }

  .helperText{
    font-size: 0.75rem;
    color: #1d253b;
    font-weight:400;
    text-align: left;
    margin-left:10px;
    line-height: 2.66;
  }
