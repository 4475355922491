/* linear-gradient(rgb(137, 137, 137),rgb(15, 15, 15)); */
.AdminLogin{
  /* display: flex; */
  min-width: 1000px;
  min-height: 682px;
  background-color: white;
}
.login-metamask-div{
  justify-content: 'flex-end';
  padding: 70px;
  margin: 20% 0 0 0;
}
.login-metamask-div .title{
  color: #8cc63f;
}

.login-metamask-div .sub{
  color: #a2a2a2;
}

.image-banner-area{
  position: relative;
  margin: 35% 0 0 0;
  justify-content: 'flex-end';
  float: right;
  padding: 30px;
  /* width: 200%; */
}

.loginDiv
{
  /* background: orange; */
  width: 100%;
  justify-content: center;
  display: flex;
  height: 100%;
  justify-content: 'flex-end';
}

.loginFormDiv
{
  /* max-width: 25%; */
  /* width: 23%; */
  padding: 30px;
  height: 47%;
  margin: 10% 0 0 0;
  border-radius: 7px;
  background: white;
  box-shadow: 0px 7px 18px #888890;
  min-width: 250px;
}

.btn-info:hover
{
  background: blue
}
.fieldIcon{
  cursor: auto;
  height: 40px;
  width:15%;
  background:white ;
  border: 1px solid #f4f4f4 ;
  border-right:transparent;
  padding:0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #525f7f;
  border-radius: 7px 0px 0px 7px ;
}
.loginBtnDiv
{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.dontHaveAcc{
  font-size: 12px;
  text-align:center
}

.loginTitle{
  color: black;
  font-size: 26px;
  font-weight: 500;
  margin-bottom:15px;
  text-align:center;
}
