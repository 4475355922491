.add-btn{
    min-width: 120px;
    height: 30px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }
  .ListModalFooter{
    display: flex;
      width: 100%;
      justify-content: space-around;
  }
  