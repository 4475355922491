.validatorForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  max-width: 320px;
  width: 100%;
  /* background-color: yellow; */
}
.MyTextField{
  width: 100%;
  min-width: 250px;
  max-width: 350px;
  min-height: 50px;
  /* background-color: red; */
}
