.loader-container{
  background: #000000D9;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  display:flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  flex-direction: column;
}

.loader-content {
  text-align: center;
  position: absolute;
  top: 50vh;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}