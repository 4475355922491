.validatorListForm {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  min-width: 250px;
  width: 100%;
}
.iconButton {
  border: none !important; /* Remove borders */
  color: white !important; /* White text */
  padding: 12px 16px !important; /* Some padding */
  font-size: 16px !important; /* Set a font size */
  cursor: pointer;
}
.MyTextField {
  width: 100%;
  min-width: 250px;
  max-width: 350px;
  min-height: 50px;
  /* background-color: red; */
}
.divider-heading {
  width: 100%;
}
.text-editor {
  margin-bottom: 10px;
  position: relative;
}
.delete-editor {
  position: absolute;
  right: 0;
}

.dropZone {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(146, 191, 85);
  border-radius: 6px;
  cursor: pointer;
  padding: 20px;
  color: white;
  font-weight: 600;
}

.dropZoneEdit *{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(146, 191, 85);
  border-radius: 6px;
  cursor: pointer;
  width: 190px;
  color: white;
  font-weight: 600;
  height: 30px;
}

.list-new .react-datepicker__tab-loop{
   position: relative;
}

.list-new  .react-datepicker-popper{
  transform: none !important;
  z-index: 4;
}

.list-new .react-datepicker-wrapper {
  width: 100%;
}

.list-new .react-datepicker-wrapper input {
  font: inherit;
  color: currentColor;
  width: 100%;
  margin: 0;
  min-width: 0;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);;
  padding: 10.5px 14px 10.5px;
  margin-top: 8px;
    margin-bottom: 4px;
}
.dropZoneEdit:hover * {
  background: #76ab30;
  background-image: -webkit-gradient(
    linear,
    right top,
    left bottom,
    from(#76ab30),
    color-stop(#577d23),
    to(#76ab30)
  );
  background-image: linear-gradient(to bottom left, #76ab30, #577d23, #76ab30);
  background-size: 210% 210%;
  background-position: top right;
  background-color: #76ab30;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
}

.imageList {
  height: 200px;
  max-width: 230px;
}

.imageListContainer {
  display: flex;
  flex-wrap: wrap;
}
.removeBtn {
  position: absolute;
  z-index: 1000;
  left: 0;
}
.singleImage {
  position: relative;
  margin: 5px 5px;
}
.documentName {
  background: #f4f4f4;
  padding: 18px;
  margin: 10px 10px 0px 0px;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.map-area{
  position:relative;
  padding:20px 0 50px
}
.map-area h3{
  font-size:22px;
  color:#1a1e2c;
  margin-bottom:30px
}
.map-area .map-outer{
  position:relative;
  height:320px;
  width:100%
}
.map-area .map-canvas{
  position:absolute;
  height:100%;
  width:100%
}
