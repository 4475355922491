.CardHeaderText
{
  text-align: right;
}
.dashboardContent{
  width: 100%;
  height: 100%;
  /* background: orange; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.shaddow{
  box-shadow: 0px 7px 18px #888890;
  height:300px
}
.shaddow:hover{
  box-shadow: 0px 7px 18px black;
}
.updates{
  width: 50%;
  background: white;
  margin: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row{
  /* height: 300px; */
}
.CardPanel
{
  width: 100%;
  padding: 0 10% 0% 10%;
}
.cardLogo1
{
  background: linear-gradient(to right, rgb(242, 16, 50) 57%, rgba(193, 1, 30, 0.6) 100%);
  height: 100%;
  width: 80px;
  height: 80px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
font-size: 26px;
color: white;
}

.cardLogo2
{
  background: linear-gradient(to right, rgb(66, 134, 244) 57%, rgba(30, 1, 173, 0.6) 100%);
  height: 100%;
  border-radius: 50%;
  width: 80px;
  height: 80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 26px;
color: white;
}
.cardLogo3
{
  background: linear-gradient(to right, rgb(49, 224, 237) 57%, rgba(68, 191, 185, 0.6) 100%);
  height: 100%;
  border-radius: 50%;
  width: 80px;
  height: 80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 26px;
color: white;
}

.cardLogo4
{
  background: linear-gradient(to right, rgb(109, 214, 64) 57%, rgba(83, 224, 22, 0.6) 100%);
  height: 100%;
  border-radius: 50%;
  width: 80px;
  height: 80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 26px;
color: white;
}
.CardFontSize
{
  font-size: 16px
}
