.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #f4f4f4 !important;
  border-color: #e3e3e3 #e3e3e3 rgb(201, 201, 201) !important;
  font-weight: 700;
}
.property-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.modal-content-input {
  background-color: white !important;
}

.property-overview .symbol {
  margin-left: 5px;
  font-size: 11px;
  font-weight: 800;
}

.carousel-edit-image {
  width: 200px;
  height: 200px;
  object-fit: scale-down;
}

.loader-edit-property {
  background: rgba(0, 0, 0, 0.8);
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loader-edit-property-text {
  font-size: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  color: white !important;
}
