.add-btn{
  min-width: 120px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
.ListModalFooter{
  display: flex;
    width: 100%;
    justify-content: space-around;
}
.card-information{
  /* box-shadow: 10px 10px 10px 3px rgba($black,0.1); */
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  width: 40%;
  padding-left: 2%;
  padding-right: 2%;
  padding: 2% 2% 0 2%;
  background-color: white;
  height: 150px;
  font-size: 20px;
}
.numberFormat-Form{
  padding: 6px;
  border: 1px solid #A2A2A2 !important;
  width: 77.5%;
  /* border-radius: 325px; */
  margin-top: 8px;
}
.numberFormat-Form:focus{
  /* border: 1.5px solid #8cc63f !important; */
  outline: none;
}

/* .card-information{
  display: flex;
  flex-wrap: nowrap;
  padding: 20px;
  width: 100%;
  margin: 50px 0;
  transition: all 0.3s ease-in-out;
  .icon-box {
    width: calc(20% - 0px);
    text-align: center;
    margin-top: 16px;
  }
  .text-area {
    width: calc(60% - 0px);
    text-align: center;
  }
  .detail-area {
    width: calc(20% - 0px);
    text-align: right;
    a {
      color:#828282;
      font-size: 61px;
      line-height: 17px;
    }
  }

  &:hover,
  &.active{
    background-color: #f1f1f1;
    border-left: 5px solid #89c03e;
  }
} */
