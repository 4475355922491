.new-event .blogs-header{
    display: flex;
    justify-content: space-between;
}

.new-event .react-datepicker-wrapper{
    width: 100%;
}

.new-event .react-datepicker-popper {
    z-index: 2;
}

.new-event .react-datepicker-wrapper .MuiOutlinedInput-input {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 10px 15px;
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
}

.new-event .MuiFormControl-fullWidth{
  max-width: 100%;
}

.react-datepicker-wrapper {
	position: relative;
}